.custom-product {
  .ant-input {
    width: 80%;
  }
  .ant-select {
    width: 80%;
  }
  p {
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 20px;
  }
  h3 {
    font-size: 18px;
    margin-bottom: 4px;
    margin-top: 20px;
  }
  .Add-button {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  .variants {
    padding: 20px;
    border: 2px solid #e8e8e8;
  }
  .ant-table-wrapper {
    padding-bottom: 5px;
    background: white;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: 40px;
    thead {
      tr {
        th {
          border-radius: 0 !important;
          background: #16273b;
          color: white;
        }
      }
    }
  }
}
