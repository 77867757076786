.product-card {
  .ant-card-body {
    padding: 0;
  }
  .card-header {
    padding: 15px 20px;
  }
  .blue-text {
    color: #0068ff;
  }
  .grey-text {
    color: #676666;
  }
  .pink-text {
    color: #ff005e;
  }
  p {
    margin-bottom: 7px;
  }
  .product-details {
    display: flex;
    .product-image-container {
      width: 100%;
      max-width: 77px;
      background-size: contain !important;
      margin-right: 10px;
      border: 1px solid #707070;
    }
  }
  table {
    border-radius: 0;
    thead {
      tr {
        th {
          border-radius: 0 !important;
          background: #16273b;
          color: white;
        }
      }
    }
  }
  .variant-image {
    display: flex;
    align-items: center;
    img {
      margin-right: 3px;
    }
  }

  .clonerowprice {
    display: flex;
    justify-content: space-between;
  }
}
