.orders-confirmation-modal {
  .ant-modal-header {
      padding: 16px 40px;
      color: rgba(0,0,0,0.85);
      .ant-modal-title {
          font-size: 18px;
      }
  }
  .ant-modal-body {
      padding: 0;
  }
  .heading {
      padding: 20px 40px;
      color: #5D5C5C;
      font-size: 14px;
  }
  .selected-orders {
     .header-row {
         padding: 15px 40px;
         background: #16273B;
         color: #fff;
         .ant-col:nth-of-type(3),
         .ant-col:nth-of-type(4),
         .ant-col:nth-of-type(5) {
             text-align: center;
         }
     }
     .line-item {
         padding: 20px 40px;
         .ant-col:nth-of-type(3),
         .ant-col:nth-of-type(4),
         .ant-col:nth-of-type(5) {
             text-align: center;
         }
         .orderid {
          color: #0068FF;
         }
         .line-item-img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: inline-block;
          background-size: cover !important;
          background-position: center !important;
        }
        .line-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
          font-size: 14px;
          color: #16273B;
        }
        .line-item-sku {
          font-size: 12px;
          color: #676666;
          margin: 0;
        }
     }
     .line-item:nth-of-type(2n) {
         background: #F2F2F2;
     }
  }
}