.order-card {
  margin-bottom: 3%;
  .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
    background: #3587ff;
  }
  .ant-btn-group {
    .ant-btn {
      vertical-align: middle;
    }
  }
  .hidden {
    display: none;
  }
  .ant-card-body {
    padding: 0;
  }
  .blue-text {
    color: #0068ff;
  }
  .mr-3 {
    margin-right: 10px;
  }
  span {
    vertical-align: middle;
    i {
      vertical-align: middle;
    }
  }
  .header-row {
    display: flex;
    align-items: center;
    padding: 26px 28px 20px 28px;
    h3 {
      font-size: 16px;
      font-weight: 400;
    }
    .add-note-btn,
    .view-notes-btn {
      border-color: #0068ff;
      color: #0068ff;
    }
    .add-note-btn:hover,
    .view-notes-btn:hover {
      border-color: #0068ff;
      background: #0068ff;
      color: white;
    }
  }
  .tabs-row {
    background: #16273b;
    padding: 0 20px 0 28px;
    display: flex;
    align-items: center;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 12px 24px;
        color: white;
        cursor: pointer;
      }
      li.active,
      li:hover {
        background: #244060;
      }
    }
    .bulk-action-btn {
      background: transparent;
      color: white;
      border: 2px solid #fff;
      border-radius: 4px;
    }
    .ant-avatar {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
    }
  }
  .tab-container {
    background: #f2f2f2;
    padding: 0 27px 12px 27px;
  }
  .customer-info-tab {
    background: white;
    padding: 25px 15px;
    .ant-row {
      margin-bottom: 15px;
    }
    .ant-input {
      border-radius: 2px;
    }
    .ant-input:read-only {
      background: #f7f6f6;
    }
    p {
      margin: 15px 0 5px 0;
      color: #797878;
      font-size: 14px;
    }
    .ant-form-item-control {
      line-height: 30px;
    }
    .btn-edit-customer {
      color: #ff005e;
      border: 1px solid #ff005e;
      border-radius: 4px;
      font-size: 12px;
      width: 50%;
    }
    .btn-edit-customer:hover {
      color: #fff;
      background: #ff005e;
    }
    .btn-save-customer {
      background: #73d13d;
      border-color: #73d13d;
      color: white;
      border-radius: 4px;
      font-size: 12px;
      width: 50%;
    }
  }
  .line-items-tab {
    padding: 18px 13px 7px 13px;
    background: #fff;
    .line-item {
      background: #f2f2f2;
      margin-bottom: 10px;
      padding: 14px 12px;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      .ant-checkbox-wrapper {
        transform: scale(1.15);
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border: 1px solid #aaa;
        }
      }
      .quantity-field {
        max-width: 65px;
        margin-right: 5px;
      }
      .anticon {
        font-size: 22px;
      }
      .line-item-product-detail {
        display: flex;
        align-items: center;
      }
      .line-item-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        border: 1px solid #c8c8c8;
      }
      .line-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 15px;
      }
      .line-item-sku {
        font-size: 14px;
        color: #676666;
        font-weight: 400;
        margin: 0;
        margin-top: -2px;
      }
    }
    .line-item.selected {
      background: #eeffe5;
      border: 1px solid #73d13d;
    }
    .edit-quantity-btn {
      background: transparent;
      border: 1px solid #ff005e;
      border-radius: 4px;
      color: #ff005e;
    }
    .edit-quantity-btn:hover {
      background: #ff005e;
      border: 1px solid #ff005e;
      box-shadow: 0 1px 2px rgba(255, 0, 94, 0.4);
      color: white;
    }
  }
  .shipments-tab {
    padding: 23px 13px;
    background: #fff;
    .no-shipment {
      text-align: center;
    }
    .shipment {
      margin-bottom: 15px;
    }
    .shipment-header {
      background: #f2f2f2;
      padding: 28px 20px;
      margin-bottom: 5px;
      color: black;
    }
    .shipment-items {
      background: #eff5ff;
      padding: 5px 10px;
    }
    .shipment-item {
      background: transparent;
      padding: 14px 12px;
      display: flex;
      align-items: center;
      .ant-checkbox-wrapper {
        transform: scale(1.15);
      }
      .ant-checkbox {
        .ant-checkbox-inner {
          border: 1px solid #aaa;
        }
      }
      .anticon {
        font-size: 22px;
      }
      .shipment-item-product-detail {
        display: flex;
        align-items: center;
      }
      .shipment-item-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
      }
      .shipment-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 15px;
      }
      .shipment-item-sku {
        font-size: 14px;
        color: #676666;
        font-weight: 400;
        margin: 0;
        margin-top: -2px;
      }
    }
    .shipment-item:nth-of-type(2n) {
      background: white;
    }
  }

  .notes-tab,
  .vendornotes-tab {
    padding: 23px 13px;
    background: #fff;
    .note {
      background: #f2f2f2;
      margin-bottom: 15px;
      padding: 14px 22px;
    }
  }
}
.tatFailed {
  color: black;
  border: 2px solid #fb9898;
}
@media screen and (max-width: 1280px) {
  .show-big {
    display: none;
  }
  .show-small {
    display: unset;
  }
}
@media screen and (min-width: 1281px) {
  .show-big {
    display: unset;
  }
  .show-small {
    display: none;
  }
}
