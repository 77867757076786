.sidebar {
  border-right: 1px solid #bfbfbf;
  .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu {
    background: #f7f7fc;
  }
  .submenu-item {
    padding-left: 48px !important;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    color: rgba(29, 29, 29, 0.65);
    a:hover {
      color: black;
    }
  }
  .ant-menu-item:hover {
    background-color: transparent !important;
    color: black;
    a {
      color: black;
    }
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    a {
      color: black;
      font-weight: 500;
    }
  }
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
    color: black;
    font-weight: 500;
  }
  .ant-menu-item-selected:after {
    display: none;
  }
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  .show-on-small-screen {
    display: none;
  }

  .order-count {
    font-size: 14px;
    background: linear-gradient(135deg, #ff006c, #ff0045);
    padding: 2px 10px;
    color: white;
    border: 1px solid white;
    border-radius: 13px;
    height: 25px;
    line-height: 20px;
    min-width: 30px;
    text-align: center;
    float: right;
    margin-right: -12px;
    margin-top: 6px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.23);
  }
}
@media (max-width: 768px) {
  .sidebar .show-on-small-screen {
    display: block;
  }
  .sidebar-open {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
  }
}
