html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 100%;
}
#root {
  height: 100%;
}
.content-body {
  height: 100%;
}
.bold {
  font-weight: bold;
}
a {
  text-decoration: none !important;
}
.ant-btn-primary {
  background-color: #0068ff !important;
  border-color: #0068ff !important;
}
@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 4 * ((100vw - 320px) / 961));
  }
}
// @media screen and (max-width: 1280px) {
//   html {
//     zoom: 0.75;
//   }
// }
@media screen and (min-width: 1281px) {
  html {
    font-size: 16px;
  }
}
