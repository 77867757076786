.profile-form {
    color: #000;
    background: #fff;
    border: 1px solid #ede9e9;

    .column {
        padding: 1.5rem;
        padding-bottom: 0;
    }

    .button-container {
        text-align: right;

        .ant-btn-primary {
            background: #92c15f;
            border-color: #92c15f;
        }
        .ant-btn-primary:hover, .ant-btn-primary:focus {
            background: #69bd42;
            border-color: #69bd42;
        }
    }
    @media (max-width: 576px) {
        .button-container {
            text-align: center;
        }
    }

    .ant-divider-horizontal {
        margin: 1rem 0;
    }
}