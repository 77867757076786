.sourcing-table {
  .blue-text {
    color: #0068FF;
  }
  thead {
    tr {
      th {
        border-radius: 0 !important;
        background: #16273B;
        color: white;
      }
    }
  }
}